import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../layout/sidenav/sidenav.service';
import { ApiService } from './api.service';
import { EventService } from './event.service';
import { StorageService } from './storage.service';
import * as moment from 'moment';
export interface AddressInterface { 
  streetAddress: string,
  city: string,
  state: string,
  zip: string,
  unitNumber?: string
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private storage: StorageService,
    private eventService: EventService,
    private sidenavService: SidenavService,
    private router: Router,
    private apiService: ApiService
) { }

clearAfterLogout(message: string, type: string) {
    this.storage.clearUser();
    this.eventService.setLoginEmmit(false);
    this.router.navigate(['/login']);
    this.sidenavService.removeItems();
    this.eventService.count.next(0);
    this.apiService.alert(message, type);
}
searchTypeValue: any[] = [
  {title: 'Contains', value: 'contains'},
  {title: 'Starts With', value: 'startsWith'},
  {title: 'Not Contains', value: 'notContains'},
  {title: 'End With', value: 'endWith'},
  {title: 'Equals', value: 'equals'},
  {title: 'Not Equals', value: 'notEquals'},
]
  /**
   * @param firstName first name of user, replace with empty string if undefined or null.
   * @param lastName last name of user.
   * @returns fullname by concatenating first and last name with a space.
   */
  getFullName(firstName: string, lastName: string): string {
    return [firstName, lastName]
      .map((name) => name?.trim() ?? name)
      .join(' ')
      .trim();
  }

  formatAddress(fullAddress: AddressInterface) {
    if (fullAddress.streetAddress) {
      let address: string = fullAddress.streetAddress;
      if (address.includes(`#${fullAddress.unitNumber}`)) {
        return address;
      } else {
        let splitString = address.split(',');
        if (fullAddress.unitNumber)
          splitString.splice(1, 0, ' #' + fullAddress.unitNumber);
        return splitString.toString();
      }
    }  return '';
  }
  convertDate(date: any){
   if(date) return new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York' });
   return '';
  }

  daysRemaining(DateStamp: Date | string) {
    if(DateStamp === null) return 'On-going';
    const eventDate = moment(DateStamp);
    const todaysDate = moment();
    const diffDate =  eventDate.diff(todaysDate, 'days');
    return diffDate > 0 ? diffDate : 0;
}

  daysActive(DateStamp: Date | string) {
    const eventDate = moment(DateStamp);
    const todaysDate = moment();
    const diffDate =  todaysDate.diff(eventDate, 'days');
    return diffDate > 0 ? diffDate : 0;
  }

  ninetyDaysOld(DateStamp: Date | string) {
    if(!DateStamp) return false;
    const eventDate = moment(DateStamp);
    const todaysDate = moment();
    const diffDate =  todaysDate.diff(eventDate, 'days');
    return diffDate < 90;
  }

  renewPeriod(DateStamp: Date | string) {
    if(!DateStamp) return false;
    const eventDate = moment(DateStamp);
    const todaysDate = moment();
    const diffDate =  eventDate.diff(todaysDate, 'days');
    return diffDate < 90;
  }
  getStreetAddress(propertyAddress){
    const address = propertyAddress?.address || propertyAddress?.streetAddress;
    if(!address) return '';
    return `${address?.split(',')[0]}, ${propertyAddress?.state},  ${propertyAddress?.city}, ${propertyAddress?.zip || propertyAddress?.zipCode}`
  }
  getCardType(cardNumber: string): string {
    const cardNumberDigitsOnly = cardNumber.replace(/\D/g, '');
    const firstDigit = cardNumberDigitsOnly.charAt(0);
    if (firstDigit === '4') {
      return 'Visa';
    } else if (firstDigit === '5') {
      return 'Mastercard';
    } else if (firstDigit === '3') {
      return 'American Express';
    } else if(firstDigit === '6'){
      return 'Discover'
    } else {
      return 'Unknown';
    }
  }
}
