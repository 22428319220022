<div class="claim_body">
    <div class="top_panel">
        <h3>Special Request</h3>
        <span (click)="close()" class="close">
            <img src="https://cdn.elitehw.com/images/cross.webp" />
        </span>
    </div>
    <div class="formarea">
        <form [formGroup]="specialRequestForm">
            <div class="field_area">
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                    <div class="input_wrap">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Request Status</mat-label>
                                <mat-select formControlName="status">
                                  <mat-option value="New">New</mat-option>
                                  <mat-option value="Hold">Hold</mat-option>
                                  <mat-option value="Processing">Processing</mat-option>
                                  <mat-option value="Completed">Completed</mat-option>
                                </mat-select>
                                <mat-error  *ngIf="specialRequestForm.controls['status'].touched && specialRequestForm.controls['status'].hasError('required')">
                                    This is required field.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="input_wrap">
                       
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Agreement Number</mat-label>
                                <input matInput placeholder="Agreement Number" formControlName="agreementNumber"  [readonly]="!!agreementNumber">
                            </mat-form-field>
                        </div>
                    </div>
                    
                    
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                    <div class="input_wrap">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Request Type</mat-label>
                                <mat-select formControlName="type">
                                  <mat-option value="Update Information">Update Information</mat-option>
                                  <mat-option value="Update Effective Date">Update Effective Date</mat-option>
                                  <mat-option value="Update Payment">Update Payment</mat-option>
                                  <mat-option value="Update Billing Date">Update Billing Date</mat-option>
                                  <mat-option value="Email Agreement">Email Agreement</mat-option>
                                  <mat-option value="Mail Hard Copy">Mail Hard Copy</mat-option>
                                  <mat-option value="Cancellation">Cancellation</mat-option>
                                  <mat-option value="Referral Bonus">Referral Bonus</mat-option>
                                  <mat-option value="Refund">Refund</mat-option>
                                  <mat-option value="Claim Update">Claim Update</mat-option>
                                  <mat-option value="Claim Payout">Claim Payout</mat-option>
                                  <mat-option value="Claim Dispute">Claim Dispute</mat-option>
                                  <mat-option value="Other">Other</mat-option>
                                </mat-select>
                                <mat-error  *ngIf="specialRequestForm.controls['type'].touched && specialRequestForm.controls['type'].hasError('required')">
                                    This is required field.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="input_wrap">
                       
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Priority</mat-label>
                                <mat-select formControlName="priority">
                                  <mat-option value="Low">Low</mat-option>
                                  <mat-option value="Medium">Medium</mat-option>
                                  <mat-option value="High">High</mat-option>
                                  <mat-option value="Critical">Critical</mat-option>
                                </mat-select>
                                <mat-error  *ngIf="specialRequestForm.controls['priority'].touched && specialRequestForm.controls['priority'].hasError('required')">
                                    This is required field.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                   
                    
                </div>

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                    <div class="input_wrap full-width">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Assigned</mat-label>
                                <input type="text"
                                       placeholder="Assigned"
                                       aria-label="Assigned"
                                       matInput
                                       formControlName="assigned"
                                       [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="searchUserService.displayResult.bind(this)">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{ commonService.getFullName(option?.firstName, option?.lastName) }}  {{option?.role?.title ? '- ' + option?.role?.title :''}}
                                      </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                        </div>
                    </div>
                    
                </div>

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                    <div class="input_wrap full-width">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Request</mat-label>
                                <textarea matInput
                                          cdkTextareaAutosize
                                          #autosize="cdkTextareaAutosize"
                                          cdkAutosizeMinRows="5"
                                          cdkAutosizeMaxRows="5" 
                                          formControlName="request"></textarea>
                                          <mat-error  *ngIf="specialRequestForm.controls['request'].touched && specialRequestForm.controls['request'].hasError('required')">
                                            This is required field.
                                        </mat-error>
                            </mat-form-field>
                        </div>
                        
                    </div>
                    
                </div>
              
            </div>
        </form>
        <div class="actions"  fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" style="padding: 20px 0 0 0;">
            <ng-container>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="!isView">
                    <button mat-button type="button" class="sub-button" *ngIf="!isLoading" (click)="handleSpecialRequestSubmit()">{{requestId ? 'Update' : 'Add'}}</button>
                    <img [ngSrc]="assetsUrl + 'loder.gif'" alt="loaderImg" width="150" height="30" class="loadimg" *ngIf="isLoading"/>
                    <button class="can-button" mat-button *ngIf="!isLoading" (click)="redirectToDashboard()">Cancel</button>
                </div>

            <!-- <button mat-button type="button" class="sub-button" (click)="assignAgreement()"><span>Assign</span></button>
            <button mat-button type="button" class="can-button" (click)="close()"><span>Cancel</span></button> -->
        </ng-container>
        </div>
        <div *ngIf="requestId" class="history_Prt">
            <h2 style="font-size: 1.3rem;
            font-weight: 600;">Special Request History</h2>
            <fury-special-request-table
            [displayedColumns]="displayedColumns"
            [dataSource]="specialRequestHistories"
            [userId]="userId"
            [editorAccess]="false"
          ></fury-special-request-table>
        </div>
       
    </div>
</div>








<!-- <fury-page-layout mode="card">
    <fury-page-layout-header>
        <div class="top_heading">
            <fury-breadcrumbs [@fadeInRight] [current]="isView ? 'View Special Request' : requestId ? 'Update Special Request' : 'Add Special Request'"></fury-breadcrumbs>
            <button mat-button class="can-button" (click)="redirectToDashboard()"><mat-icon>keyboard_backspace</mat-icon> Back</button>
        </div>
    </fury-page-layout-header>
    <fury-page-layout-content [@fadeInUp]>
        <div class="two_section">
            <div class="customer_leftinfo">
                <fury-customer-side-menu ></fury-customer-side-menu>
            </div>
            <div class="righpanel">
                <div [@fadeInUp] class="card">
                    <form [formGroup]="specialRequestForm">
                        <div class="field_area">
                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                                <div class="input_wrap">
                                    
                                    <div class="input_sec">
                                        <mat-form-field fxFlex="auto" appearance="outline">
                                            <mat-label>Request Status</mat-label>
                                            <mat-select formControlName="status">
                                              <mat-option value="New">New</mat-option>
                                              <mat-option value="Hold">Hold</mat-option>
                                              <mat-option value="Processing">Processing</mat-option>
                                              <mat-option value="Completed">Completed</mat-option>
                                            </mat-select>
                                            <mat-error  *ngIf="specialRequestForm.controls['status'].touched && specialRequestForm.controls['status'].hasError('required')">
                                                This is required field.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="input_wrap">
                                   
                                    <div class="input_sec">
                                        <mat-form-field fxFlex="auto" appearance="outline">
                                            <mat-label>Agreement Number</mat-label>
                                            <input matInput placeholder="Agreement Number" formControlName="agreementNumber"  [readonly]="!!queryParams?.an">
                                        </mat-form-field>
                                    </div>
                                </div>
                                
                                
                            </div>
                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                                <div class="input_wrap">
                                    
                                    <div class="input_sec">
                                        <mat-form-field fxFlex="auto" appearance="outline">
                                            <mat-label>Request Type</mat-label>
                                            <mat-select formControlName="type">
                                              <mat-option value="Update Information">Update Information</mat-option>
                                              <mat-option value="Update Effective Date">Update Effective Date</mat-option>
                                              <mat-option value="Update Payment">Update Payment</mat-option>
                                              <mat-option value="Update Billing Date">Update Billing Date</mat-option>
                                              <mat-option value="Email Agreement">Email Agreement</mat-option>
                                              <mat-option value="Mail Hard Copy">Mail Hard Copy</mat-option>
                                              <mat-option value="Cancellation">Cancellation</mat-option>
                                              <mat-option value="Referral Bonus">Referral Bonus</mat-option>
                                              <mat-option value="Refund">Refund</mat-option>
                                              <mat-option value="Claim Update">Claim Update</mat-option>
                                              <mat-option value="Claim Payout">Claim Payout</mat-option>
                                              <mat-option value="Claim Dispute">Claim Dispute</mat-option>
                                              <mat-option value="Other">Other</mat-option>
                                            </mat-select>
                                            <mat-error  *ngIf="specialRequestForm.controls['type'].touched && specialRequestForm.controls['type'].hasError('required')">
                                                This is required field.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="input_wrap">
                                   
                                    <div class="input_sec">
                                        <mat-form-field fxFlex="auto" appearance="outline">
                                            <mat-label>Priority</mat-label>
                                            <mat-select formControlName="priority">
                                              <mat-option value="Low">Low</mat-option>
                                              <mat-option value="Medium">Medium</mat-option>
                                              <mat-option value="High">High</mat-option>
                                              <mat-option value="Critical">Critical</mat-option>
                                            </mat-select>
                                            <mat-error  *ngIf="specialRequestForm.controls['priority'].touched && specialRequestForm.controls['priority'].hasError('required')">
                                                This is required field.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                               
                                
                            </div>
            
                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                                <div class="input_wrap full-width">
                                    
                                    <div class="input_sec">
                                        <mat-form-field fxFlex="auto" appearance="outline">
                                            <mat-label>Assigned</mat-label>
                                            <input type="text"
                                                   placeholder="Assigned"
                                                   aria-label="Assigned"
                                                   matInput
                                                   formControlName="assigned"
                                                   [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="searchUserService.displayResult.bind(this)">
                                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                    {{ commonService.getFullName(option?.firstName, option?.lastName) }}  {{option?.role?.title ? '- ' + option?.role?.title :''}}
                                                  </mat-option>
                                            </mat-autocomplete>
                                          </mat-form-field>
                                    </div>
                                </div>
                                
                            </div>
            
                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                                <div class="input_wrap full-width">
                                    
                                    <div class="input_sec">
                                        <mat-form-field fxFlex="auto" appearance="outline">
                                            <mat-label>Request</mat-label>
                                            <textarea matInput
                                                      cdkTextareaAutosize
                                                      #autosize="cdkTextareaAutosize"
                                                      cdkAutosizeMinRows="5"
                                                      cdkAutosizeMaxRows="5" 
                                                      formControlName="request"></textarea>
                                                      <mat-error  *ngIf="specialRequestForm.controls['request'].touched && specialRequestForm.controls['request'].hasError('required')">
                                                        This is required field.
                                                    </mat-error>
                                        </mat-form-field>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="!isView">
                                <button mat-button type="button" class="sub-button" *ngIf="!isLoading" (click)="handleSpecialRequestSubmit()">{{requestId ? 'Update' : 'Add'}}</button>
                                <img [ngSrc]="assetsUrl + 'loder.gif'" alt="loaderImg" width="150" height="30" class="loadimg" *ngIf="isLoading"/>
                                <button class="can-button" mat-button *ngIf="!isLoading" (click)="redirectToDashboard()">Cancel</button>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="requestId" class="history_Prt">
                        <h2>Special Request History</h2>
                        <fury-special-request-table
                        [displayedColumns]="displayedColumns"
                        [dataSource]="specialRequestHistories"
                        [userId]="userId"
                        [editorAccess]="false"
                      ></fury-special-request-table>
                    </div>
                </div>
            </div>
        </div>
    </fury-page-layout-content>
</fury-page-layout> -->