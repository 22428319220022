import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { EventService } from 'src/app/services/event.service';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {
  userData:any;
  isOpen: boolean;

  constructor(
    private eventService:EventService, 
    private storageService: StorageService,
    private apiService: ApiService,
    private commonService: CommonService,
    private router: Router
    ) { 
    this.userData = this.storageService.getUser();
  }

  ngOnInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }
  logOut() {
    this.apiService.delete('auth/logout').subscribe((res: any) => {
      if(res.body) {
        this.commonService.clearAfterLogout(res.body.message, 'success');
        this.storageService.clearUser();
        // this.router.navigate(['/login']);
      }
    },
    err => {
      this.commonService.clearAfterLogout('Somthing went wrong', 'error');
    });    
  }
}
