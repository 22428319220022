import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { ApiService } from './services/api.service';
import { StorageService } from './services/storage.service';
import { EventService } from './services/event.service';
import { UserData } from './models/user-data.models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v5 as uuidv5 } from 'uuid';
import endpoints from './helpers/endpoints';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { CustomerDashboardService } from './pages/customer-dashboard/services/customer-dashboard.service';
import { ErrorType } from './enums/error-type.enum';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy{
  roleIds = [];
  isAdmin !:boolean;
  menuList: any[];
  count : number = 0;
  uniqueKey = '1b671a64-40d5-491e-99b0-da01ff1f3341';
  ngUnsubscribe = new Subject<void>();
  constructor(private sidenavService: SidenavService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              private themeService: ThemeService,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private route: ActivatedRoute,
              private splashScreenService: SplashScreenService,
              private apiService: ApiService,
              private storage: StorageService,
              private eventService: EventService,
              private deviceService: DeviceDetectorService,
              private customerDashboardService : CustomerDashboardService
              ) {
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.themeService.theme$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    // this.sidenavService.addItems([
    //   {
    //     name: 'Dashboard',
    //     routeOrFunction: '/',
    //     icon: 'dashboard',
    //     position: 10,
    //     pathMatchExact: true
    //   },
    //   {
    //     name: 'User Management',
    //     icon: 'account_circle',
    //     position: 15,
    //     subItems: [
    //       {
    //         name: 'Roles',
    //         routeOrFunction: '/users/role-list',
    //         position: 11
    //       },
    //       {
    //         name: 'Users',
    //         routeOrFunction: '/users/user-list',
    //         position: 12
    //       },
    //     ]
    //   },
    //   {
    //     name: 'States',
    //     routeOrFunction: '/state/state-list',
    //     icon: 'map',
    //     position: 20
    //   },
    //   {
    //     name: 'Plan Management',
    //     icon: 'description',
    //     position: 25,
    //     subItems: [
    //       {
    //         name: 'Covered Items',
    //         routeOrFunction: '/plan-management/covered-items/covered-items-list',
    //         position: 10
    //       },
    //       {
    //         name: 'Optional Items',
    //         routeOrFunction: '/plan-management/optional-items/optional-item-list',
    //         position: 11
    //       },
    //       {
    //         name: 'Plan type',
    //         routeOrFunction: '/plan-management/plan-type/plan-type-list',
    //         icon: 'format_shapes',
    //         position: 12
    //       },
    //       {
    //         name: 'Plan size',
    //         routeOrFunction: '/plan-management/plan-size/plan-size-list',
    //         icon: 'format_shapes',
    //         position: 13
    //       },
    //       {
    //         name: 'Plan price',
    //         routeOrFunction: '/plan-management/plan-price/plan-price-list',
    //         icon: 'format_shapes',
    //         position: 14
    //       }
    //     ]
    //   },
      
    //   {
    //     name: 'Leads',
    //     routeOrFunction: '/lead/lead-list',
    //     icon: 'supervisor_account',
    //     position: 30
    //   },
    //   {
    //     name: 'Homeowner Hub',
    //     icon: 'home',
    //     position: 35,
    //     subItems: [
    //       {
    //         name: 'Categories',
    //         routeOrFunction: '/homeowner/category/category-list',
    //         position: 5
    //       },
    //       {
    //         name: 'Authors',
    //         routeOrFunction: '/homeowner/authors/authors-list',
    //         position: 10
    //       },
    //       {
    //         name: 'Articles',
    //         routeOrFunction: '/homeowner/article/articles-list',
    //         position: 15
    //       }
    //     ]
    //   },
    //   {
    //     name: 'Lead Partners',
    //     routeOrFunction: '/lead-partners/partner-list',
    //     icon: 'supervised_user_circle',
    //     position: 40,
    //     subItems: [
    //       {
    //         name: 'Lead Partners',
    //         routeOrFunction: '/lead-partners/partner-list',
    //         position: 5
    //       },
    //       {
    //         name: 'Lead Partners Performance',
    //         routeOrFunction: '/lead-partners/lead-partner-performance-list',
    //         position: 10
    //       },
    //     ]
    //   },
    //   {
    //     name: 'Customer Agreement',
    //     routeOrFunction: '/customer-agreement/customer-agreement-list',
    //     icon: 'assignment',
    //     position: 45
    //   },
    //   {
    //     name: 'Settings',
    //     icon: 'settings_applications',
    //     position: 50,
    //     subItems: [
    //       {
    //         name: 'Pages',
    //         routeOrFunction: '/settings/page/page-list',
    //         position: 5
    //       },
    //       {
    //         name: 'Services',
    //         routeOrFunction: '/settings/service/service-list',
    //         position: 5
    //       },
    //       {
    //         name: 'Compare Table Content',
    //         routeOrFunction: '/settings/compare-table/compare-table-content',
    //         position: 10
    //       },
    //       {
    //         name: 'Compare Table Data List',
    //         routeOrFunction: '/settings/compare-table/compare-table-data-list',
    //         position: 10
    //       },
    //       {
    //         name: 'Menu Management',
    //         routeOrFunction: '/settings/menu/menu-list',
    //         position: 10
    //       },
    //       {
    //         name: 'Service Agreements',
    //         routeOrFunction: '/settings/service-agreement/service-agreement-list',
    //         position: 15
    //       },
         
    //     ]
    //   },
    //   {
    //     name: 'Security',
    //     icon: 'security',
    //     position: 55,
    //     subItems: [
    //       {
    //         name: 'Blacklist IP Management',
    //         routeOrFunction: '/security/blacklist-ip/blacklist-ip-list',
    //         position: 5
    //       },
    //       {
    //         name: 'All Lead Requests',
    //         subItems: [
    //           {
    //             name: 'View All Lead Requests',
    //             routeOrFunction: '/security/view-all-lead-requests/view-all-lead-requests-list'
    //           },
    //           {
    //             name: 'IP Usage Zone',
    //             routeOrFunction: '/security/view-all-lead-requests/ip-usage-list'
    //           },
    //           {
    //             name: 'Phone Usage Zone',
    //             routeOrFunction: '/security/view-all-lead-requests/phone-usage-list'
    //           },
    //           {
    //             name: 'Email Usage Zone',
    //             routeOrFunction: '/security/view-all-lead-requests/email-usage-list'
    //           }
    //         ]
    //       },
    //       {
    //         name: 'Web Lead Request',
    //         subItems: [
    //           {
    //             name: 'View All Web Lead Requests',
    //             routeOrFunction: '/security/web-lead-requests/view-all-web-lead-requests'
                
    //           },
    //           {
    //             name: 'Web Ip Usage Zone',
    //             routeOrFunction: '/security/web-lead-requests/web-ip-usage-list'
    //           },
    //           {
    //             name: 'Web Phone Usage Zone',
    //             routeOrFunction: '/security/web-lead-requests/web-phone-usage-list'
    //           },
    //           {
    //             name: 'Web Email Usage Zone',
    //             routeOrFunction: '/security/web-lead-requests/web-email-usage-list'
    //           }
    //         ]
    //       },
    //       {
    //         name: 'Post Lead Request',
    //         subItems: [
    //           {
    //             name: 'View All Post Lead Requests',
    //             routeOrFunction: '/security/post-lead-requests/view-all-post-lead-requests'
                
    //           },
    //           {
    //             name: 'Post Ip Usage Zone',
    //             routeOrFunction: '/security/post-lead-requests/post-ip-usage-list'
    //           },
    //           {
    //             name: 'Post Phone Usage Zone',
    //             routeOrFunction: '/security/post-lead-requests/post-phone-usage-list'
    //           },
    //           {
    //             name: 'Post Email Usage Zone',
    //             routeOrFunction: '/security/post-lead-requests/post-email-usage-list'
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     name: 'Reputation',
    //     icon: 'turned_in',
    //     position: 60,
    //     subItems: [
    //       {
    //         name: 'Review Url',
    //         routeOrFunction: '/reputation/review-url',
    //         position: 5
    //       },
    //       {
    //         name: 'Feedback',
    //         routeOrFunction: '/reputation/feedback',
    //         position: 10
    //       },
    //       {
    //         name: 'Statistics Review',
    //         routeOrFunction: '/reputation/statistics-review',
    //         position: 15
    //       },
    //       {
    //         name: 'User Review',
    //         routeOrFunction: '/reputation/user-review-list',
    //         position: 20
    //       },
         
    //     ]
    //   },
    //   {
    //     name: 'Audience',
    //     icon: 'stars',
    //     position: 65,
    //     routeOrFunction: '/audience/analytics-list',
    //   },
    //   {
    //     name: 'Partners',
    //     icon: 'supervisor_account',
    //     position: 70,
    //     subItems: [
    //       {
    //         name: 'Affiliates',
    //         subItems: [
    //           {
    //             name: 'Pending',
    //             routeOrFunction: '/partners/affiliates/pending'
               
    //           },
    //           {
    //             name: 'Approved',
    //             routeOrFunction: '/partners/affiliates/approved'
    //           },
    //           {
    //             name: 'Declined',
    //             routeOrFunction: '/partners/affiliates/declined'
    //           }
    //         ]
    //       },
    //       {
    //         name: 'Real Estate Professionals',
    //         subItems: [
    //           {
    //             name: 'Pending',
    //             routeOrFunction: '/partners/real-estate-professionals/pending'
                
    //           },
    //           {
    //             name: 'Approved',
    //             routeOrFunction: '/partners/real-estate-professionals/approved'
    //           },
    //           {
    //             name: 'Declined',
    //             routeOrFunction: '/partners/real-estate-professionals/declined'
    //           }
    //         ]
    //       },
    //       {
    //         name: 'Service Providers',
    //         subItems: [
    //           {
    //             name: 'Pending',
    //             routeOrFunction: '/partners/service-providers/pending'
                
    //           },
    //           {
    //             name: 'Approved',
    //             routeOrFunction: '/partners/service-providers/approved'
    //           },
    //           {
    //             name: 'Declined',
    //             routeOrFunction: '/partners/service-providers/declined'
    //           }
    //         ]
    //       },
    //       {
    //         name: 'Suppliers',
    //         subItems: [
    //           {
    //             name: 'Pending',
    //             routeOrFunction: '/partners/suppliers/pending'
                
    //           },
    //           {
    //             name: 'Approved',
    //             routeOrFunction: '/partners/suppliers/approved'
    //           },
    //           {
    //             name: 'Declined',
    //             routeOrFunction: '/partners/suppliers/declined'
    //           }
    //         ]
    //       },
    //       {
    //         name: 'Title Agencies',
    //         subItems: [
    //           {
    //             name: 'Pending',
    //             routeOrFunction: '/partners/title-agencies/pending'
                
    //           },
    //           {
    //             name: 'Approved',
    //             routeOrFunction: '/partners/title-agencies/approved'
    //           },
    //           {
    //             name: 'Declined',
    //             routeOrFunction: '/partners/title-agencies/declined'
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]);
   this.eventService.userData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res : UserData)=>{
    if(res.roleIds){
      this.roleIds = res.roleIds;
      this.isAdmin = res.isAdmin;
      this.getMenuList();
      this.getClaimStatus();
      this.getAgents();
    }
   });
   this.eventService.count.pipe(takeUntil(this.ngUnsubscribe)).subscribe((count: number)=>{
    this.count = count;
   })
   this.getDeviceInformation(); 
   if (this.storage.getIp() === null || this.storage.getIp() === undefined)
   this.apiService.getlocation();
    
  }
  ngOnInit() {
    
  }
  getMenuList(){
    let apiUrl = 'admin/role/get-role-with-acl';
    this.roleIds.forEach((roleId: string, ind: number)=>{
      if(ind === 0) apiUrl = `${apiUrl}?roleId[${ind}]=${roleId}`;
      if(ind > 0) apiUrl = apiUrl+`&roleId[${ind}]=${roleId}`;
    });
    this.apiService.get(apiUrl).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any)=>{
      if(res.body){
      this.menuList =  this.convertDataFormat(res.body?.acl);
      let dashboard = {
        name: 'Dashboard',
        routeOrFunction: '/dashboard',
        icon: 'dashboard',
        position: 5,
      }
      if(this.count === 0){
        // if(this.isAdmin) this.menuList.unshift(dashboard);
        this.sidenavService.addItems(this.menuList);
       this.eventService.count.next(1);
      }
     
      }
      
    })
  };
   convertDataFormat(data: any[]) {
    return data.map((item: any, ind : number) => {
      const { title, frontendComponentName, children, icon } = item;
      const subItems = children?.length > 0 ? this.convertDataFormat(children) : [];
      
      return {
        name: title,
        routeOrFunction: frontendComponentName,
        subItems: subItems,
        position : (ind + 1) * 5,
        icon: icon,
      };
    });
  }
  getDeviceInformation() {
    this.eventService.UserDeviceType.next(this.deviceService.getDeviceInfo());
  }
  setSessionId(){
    const data = {
      connection: (window as any).clientInformation.connection,
      deviceMemory: (window as any).clientInformation.deviceMemory,
      hardwareConcurrency: (window as any).clientInformation
        .hardwareConcurrency,
      language: (window as any).clientInformation.language,
      platform: (window as any).clientInformation.platform,
      productSub: (window as any).clientInformation.productSub,
      jsHeapSizeLimit: (window as any).performance.memory
        ? (window as any).performance.memory.jsHeapSizeLimit
        : '',
      height: (window as any).screen.height,
      width: (window as any).screen.width,
      colorDepth: (window as any).screen.colorDepth,
      ip: this.storage.getIp()?.toString(),
    };
    this.storage.setSessionId(uuidv5(JSON.stringify(data), this.uniqueKey));
  }
  getClaimStatus(){
    this.apiService.get(endpoints.fileAClaim.claimStatus).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res: HttpResponse<any>)=>{
        if(res.body){
          this.eventService.setClaimStatus(res.body?.data || []);
        }
      },
      error: (err: HttpErrorResponse)=>{
        this.eventService.setClaimStatus([]);
      }
    })
  }

  getAgents() {
    this.apiService
    .get(
      endpoints.customerDashboard.getAgents
    )
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (res: any) => {
        if (res?.body?.agents) {
          this.customerDashboardService.setAgents(res?.body?.agents || []);
        }
      },
      (err: HttpErrorResponse) => {
        this.customerDashboardService.setAgents([]);
      }
    );
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
}
