<div class="claim_body">
    <div class="top_panel">
        <h3>{{data.isView ? 'View Feedback Request' : data?.reviewId ? 'Update Feedback Request' : 'Feedback Request'}}</h3>
        <span (click)="close()" class="close">
            <img src="https://cdn.elitehw.com/images/cross.webp" />
        </span>
    </div>
    <div class="formarea">
        <form [formGroup]="feedbackForm">
            <div class="field_area">
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                    <div class="input_wrap">
                      
                      <div class="input_sec">
                        <mat-form-field fxFlex="auto" appearance="outline">
                          <mat-label>Feedback Rating</mat-label>
                          <mat-select formControlName="rating" >
                            <mat-option value="Awful">Awful</mat-option>
                            <mat-option value="Poor">Poor</mat-option>
                            <mat-option value="Fair">Fair</mat-option>
                          </mat-select>
                          <mat-error  *ngIf="feedbackForm.controls['rating'].touched && feedbackForm.controls['rating'].hasError('required')">
                            This is required field.
                        </mat-error>

                        </mat-form-field>
                      </div>
                    </div>

                    <div class="input_wrap">
                      
                      <div class="input_sec">
                        <mat-form-field fxFlex="auto" appearance="outline">
                          <mat-label>Feedback Status</mat-label>
                          <mat-select formControlName="status">
                            <mat-option value="New">New</mat-option>
                            <mat-option value="Hold">Hold</mat-option>
                            <mat-option value="Processing">Processing</mat-option>
                            <mat-option value="Completed">Completed</mat-option>
                          </mat-select>
                          <mat-error  *ngIf="feedbackForm.controls['status'].touched && feedbackForm.controls['status'].hasError('required')">
                            This is required field.
                        </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                     
                     
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                      <div class="input_wrap">
                        
                        <div class="input_sec">
                          <mat-form-field fxFlex="auto" appearance="outline">
                            <mat-label>Agreement Number</mat-label>
                            <input matInput placeholder="Agreement Number" formControlName="agreementNumber"  [readonly]="data?.agreementNumber">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="input_wrap">
                       
                        <div class="input_sec">
                          <mat-form-field fxFlex="auto" appearance="outline">
                            <mat-label>Priority</mat-label>
                            <mat-select formControlName="priority">
                              <mat-option value="Low">Low</mat-option>
                              <mat-option value="Medium">Medium</mat-option>
                              <mat-option value="High">High</mat-option>
                              <mat-option value="Critical">Critical</mat-option>
                            </mat-select>
                            <mat-error  *ngIf="feedbackForm.controls['priority'].touched && feedbackForm.controls['priority'].hasError('required')">
                              This is required field.
                          </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                  <div class="input_wrap">
                    
                    <div class="input_sec">
                      <mat-form-field fxFlex="auto" appearance="outline">
                        <mat-label>Assigned</mat-label>
                        <input type="text"
                               placeholder="Assigned"
                               aria-label="Assigned"
                               matInput
                               formControlName="assigned"
                               [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="searchUserService.displayResult.bind(this)">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                              {{ commonService.getFullName(option?.firstName, option?.lastName) }}  {{option?.role?.title ? '- ' + option?.role?.title :''}}
                              </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                    <div class="input_wrap full-width">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Request</mat-label>
                                <textarea matInput
                                          cdkTextareaAutosize
                                          #autosize="cdkTextareaAutosize"
                                          cdkAutosizeMinRows="5"
                                          cdkAutosizeMaxRows="5" 
                                          formControlName="request"></textarea>
                                          <mat-error *ngIf="feedbackForm.controls['request'].touched && feedbackForm.controls['request'].hasError('required')">
                                            This is required field.
                                          </mat-error>
                            </mat-form-field>
                        </div>
                        
                    </div>
                    
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                   
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="!data.isView">
                    <button mat-button type="button" class="sub-button" *ngIf="!isLoading" (click)="handleFeedbackSubmit()">{{data?.reviewId ? 'Update' : 'Add'}}</button>
                    <img [ngSrc]="assetsUrl + 'loder.gif'" alt="loaderImg" width="150" height="30" class="loadimg" *ngIf="isLoading"/>
                    <button class="can-button" mat-button type="button" *ngIf="!isLoading" (click)="close()">Cancel</button>
                </div>
                
            </div>
        </form>

        <div *ngIf="data?.reviewId" class="history_Prt">
          <h2>Feedback History</h2>
          <fury-feedback-table
          [displayedColumns]="displayedColumns"
          [dataSource]="reviewHistories"
          [userId]="userId"
          [editorAccess]="false"
        ></fury-feedback-table>
        </div>
    </div>
</div>