import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AppconfigService {
  private config: any;
  constructor(private httpClient: HttpClient) { }

  async loadAppConfig(): Promise<void> {
    // Load config from json
    await this.httpClient
      .get('assets/env.json')
      .pipe(
        tap((config) => {
          this.config = config;
        }),
      )
      .toPromise();
  }
  get baseUrl(){
    return this.config.BASE_API_ENDPOINT;
  }
}
