<fury-page class="dashboard" fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="center center" [@fadeInRight]>
      <div class="landingpagebody">
        
            <h3>WELCOME</h3>
            <p>To the</p>
            <h4>Elite Home Warranty</h4>
       
      </div>
    </div>
</fury-page>