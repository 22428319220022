import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import endpoints from "src/app/helpers/endpoints";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EventService } from "src/app/services/event.service";
import { UserData } from "src/app/models/user-data.models";
import { ErrorType } from "src/app/enums/error-type.enum";
import { SearchUserService } from "../../../services/search-user.service";
import { CommonService } from "src/app/services/common.service";
import { environment } from "src/environments/environment";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpResponse } from "@angular/common/http";
@Component({
  selector: "fury-feedback-request-modal",
  templateUrl: "./feedback-request-modal.component.html",
  styleUrls: ["./feedback-request-modal.component.scss"],
})
export class FeedbackRequestModalComponent implements OnInit, OnDestroy {
  agentId = "";
  ngUnsubscribe = new Subject<void>();
  feedbackForm: FormGroup;
  filteredOptions;
  reviewHistories = [];
  displayedColumns: string[] = [
    "feedback",
    "reviewStatus",
    "assigned",
    "agreementNumber",
    "updatedOn",
    "updatedBy",
    "priority",
    "request",
  ];
  assetsUrl = environment.assetsUrl + "images/";
  isLoading = false;

  constructor(
    private apiService: ApiService,
    private eventService: EventService,
    public searchUserService: SearchUserService,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      reviewId: any;
      isView: boolean;
      agreementNumber: any;
      fileClaimId: any;
    },
    public dialogRef: MatDialogRef<FeedbackRequestModalComponent>
  ) {}

  ngOnInit(): void {
    this.eventService.userData
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: UserData) => {
        if (res.userId) {
          this.agentId = res.userId;
          this.setupFeedbackForm();
          if (this.data.isView) this.feedbackForm.disable();
        }
      });

    this.filteredOptions = this.searchUserService.getAutoCompleteData(
      this.feedbackForm.get("assigned")
    );
    if (!this.data.reviewId) return;
    this.getFeedback();
    this.getFeedbackHistory();
  }

  setupFeedbackForm() {
    const agreementNumber = this.data?.agreementNumber || null;
    this.feedbackForm = new FormGroup({
      rating: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      agreementNumber: new FormControl(agreementNumber),
      assigned: new FormControl(null),
      agentId: new FormControl(this.agentId),
      priority: new FormControl(null, [Validators.required]),
      request: new FormControl(null, [Validators.required]),
      postedBy: new FormControl(this.agentId),
    });
  }

  getFeedbackHistory() {
    if (!this.data.reviewId) return;
    this.apiService
      .get(endpoints.customerDashboard.getFeedbackHistory(this.data.reviewId))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        const history = res?.body?.reviewHistories;
        history.forEach((ele) => {
          ele.reviewStatus = ele?.reviewHistoryStatus;

          ele.reviewDetails = {
            assigned: ele?.assigned,
            agreementNumber: ele?.agreementNumber,
            createdBy: ele?.createdBy,
            priority: ele?.priority,
            request: ele?.request,
          };
        });

        this.reviewHistories = history;
      });
  }

  getFeedback() {
    this.apiService
      .get(endpoints.customerDashboard.getFeedback(this.data.reviewId))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        const review = res?.body?.review;
        this.feedbackForm.setValue({
          rating: review?.feedback,
          status: review?.reviewStatus,
          agreementNumber: review?.reviewDetails?.agreementNumber,
          assigned: review?.reviewDetails.assigned,
          agentId: review?.reviewDetails?.createdBy?.userId,
          priority: review?.reviewDetails?.priority,
          request: review?.reviewDetails?.request,
          postedBy: this.agentId,
        });
      });
  }

  handleFeedbackSubmit() {
    if (this.feedbackForm.invalid) {
      this.feedbackForm.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    let payload = this.createPayload();
    payload.assigned =
      this.feedbackForm?.get("assigned")?.value?.userId || null;
    const params = this.data?.reviewId ? `/${this.data?.reviewId}` : "";
    this.apiService
      .post(endpoints.customerDashboard.savefeedback(params), payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.isLoading = false;
          this.apiService.alert(res.body?.message, ErrorType.Success);
          this.dialogRef.close(res.body);
          // this.getFeedbackHistory();
        },
        (err) => {
          this.isLoading = false;
          this.apiService.alert(err.message, ErrorType.Error);
        }
      );
  }

  createPayload() {
    let payload = { ...this.feedbackForm.value, userId: this.data.userId };
    if (this.data?.fileClaimId) {
      payload = { ...payload, claimId: this.data.fileClaimId };
    }
    return payload;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
