<div *ngIf="visible$ | async" class="footer" fxLayout="row">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px" fxFlex="grow">
   

    <div class="name" fxShow.gt-sm>
      © 2024 by EHW. All Rights Reserved.
    </div>
  </div>
  <!-- <div fxLayout="row" fxLayoutAlign="start center">
    <button (click)="hide()" mat-icon-button type="button">
      <mat-icon class="close">close</mat-icon>
    </button>
  </div> -->
</div>
