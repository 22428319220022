import { Injectable } from "@angular/core";
import { SpecialRequestComponent } from "../components/special-request/special-request.component";
import { BehaviorSubject } from "rxjs";
import { NoteModalComponent } from "../components/modals/note-modal/note-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { FeedbackRequestModalComponent } from "../components/modals/feedback-request-modal/feedback-request-modal.component";

@Injectable({
  providedIn: "root",
})
export class CustomerDashboardService {
  private agents$ = new BehaviorSubject([]);

  public getAgent$ = this.agents$.asObservable();

  private fetchAgreementData$ = new BehaviorSubject(false);

  public shouldViewFetchAgreement$ = this.fetchAgreementData$.asObservable();

  private fetchFeedbackData$ = new BehaviorSubject(false);

  public shouldViewFetchFeedback$ = this.fetchFeedbackData$.asObservable();

  constructor(private dialog: MatDialog) {}
  private fetchNoteList$ = new BehaviorSubject(false);

  public shouldFetchNoteList$ = this.fetchNoteList$.asObservable();

  private fetchSpecialRequestList$ = new BehaviorSubject(false);

  public shouldFetchSpecialRequestList$ = this.fetchSpecialRequestList$.asObservable();

  private fetchClaimChangesLog$ = new BehaviorSubject(false);
  public shouldFetchClaimChangesLogList$ = this.fetchClaimChangesLog$.asObservable();

  private fetchLabel$ = new BehaviorSubject(false);
  public shouldFetchLabel$ = this.fetchLabel$.asObservable();
  
  private allStates$ = new BehaviorSubject([]);
  public getAllStates$ = this.allStates$.asObservable();

  setAgents(data: any) {
    this.agents$.next(data);
  }

  setFetchAgreement(data) {
    this.fetchAgreementData$.next(data);
  }

  setFetchFeedback(data) {
    this.fetchFeedbackData$.next(data);
  }

  setFetchNoteList(data: boolean) {
    this.fetchNoteList$.next(data);
  }

  setFetchSpecialRequestList(data: boolean) {
    this.fetchSpecialRequestList$.next(data);
  }

  setClaimChangesLogList(data: boolean) {
    this.fetchClaimChangesLog$.next(data);
  }

  handleAddNotes(
    userId: string,
    noteId = null,
    isView = false,
    agreementNo = null,
    ClaimId = null
  ) {
    this.setFetchNoteList(false);
    this.dialog
      .open(NoteModalComponent, {
        width: "800px",
        panelClass: "payment-info",
        data: { userId, isView, noteId, agreementNo, ClaimId },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.setFetchNoteList(true);
        }
      });
  }

  handleSpecialRequest(
    userId: any,
    requestId = null,
    isView = false,
    fileClaimId = null,
    agreementNumber = null
  ) {
    this.setFetchSpecialRequestList(false);
    this.dialog
      .open(SpecialRequestComponent, {
        data: { userId, requestId, isView, fileClaimId, agreementNumber },
        width: "800px",
        panelClass: "payment-info",
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.setFetchSpecialRequestList(true);
        }
      });
  }
  handleFeedbackRequest(
    userId: string,
    reviewId = null,
    isView = false,
    fileClaimId = null,
    agreementNumber = null
  ) {
    this.setFetchFeedback(false);
    this.dialog
      .open(FeedbackRequestModalComponent, {
        data: { userId, reviewId, isView, fileClaimId, agreementNumber },
        width: "800px",
        panelClass: "payment-info",
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.setFetchFeedback(true);
        }
      });
  }
  setFetchLabel(data: boolean) {
    this.fetchLabel$.next(data);
  }
  setStates(data: any[]) {
    this.allStates$.next(data);
  }
}
