<div class="claim_body">
    <div class="top_panel">
        <h3>{{data?.isView ? 'View Note' : data?.noteId ? 'Update Note' : 'Add Note'}}</h3>
        <span (click)="close()" class="close">
            <img src="https://cdn.elitehw.com/images/cross.webp" />
        </span>
    </div>
    <div class="formarea">
        <form  [formGroup]="addNotesForm">
            <div class="field_area">
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                    <div class="input_wrap">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Note Status</mat-label>
                                <mat-select formControlName="status">
                                  <mat-option value="New">New</mat-option>
                                  <mat-option value="Hold">Hold</mat-option>
                                  <mat-option value="Processing">Processing</mat-option>
                                  <mat-option value="Completed">Completed</mat-option>
                                </mat-select>
                                <mat-error  *ngIf="addNotesForm.controls['status'].touched && addNotesForm.controls['status'].hasError('required')">
                                    This is required field.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="input_wrap">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Assigned</mat-label>
                                <input type="text"
                                       placeholder="Assigned"
                                       aria-label="Assigned"
                                       matInput
                                       formControlName="assigned"
                                       [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="searchUserService.displayResult.bind(this)">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{ commonService.getFullName(option?.firstName, option?.lastName) }}  {{option?.role?.title ? '- ' + option?.role?.title :''}}
                                      </mat-option>
    
                            
                                </mat-autocomplete>
                              </mat-form-field>
                        </div>
                    </div>
                    
                </div>

               

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">

                    <div class="input_wrap">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Agreement Number</mat-label>
                                <input matInput placeholder="Agreement Number" formControlName="agreementNumber" [readonly]="data.agreementNo">
                                <mat-error  *ngIf="addNotesForm.controls['agreementNumber'].touched && addNotesForm.controls['agreementNumber'].hasError('required')">
                                    This is required field.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="input_wrap">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Priority</mat-label>
                                <mat-select formControlName="priority">
                                  <mat-option value="Low">Low</mat-option>
                                  <mat-option value="Medium">Medium</mat-option>
                                  <mat-option value="High">High</mat-option>
                                  <mat-option value="Critical">Critical</mat-option>
                                </mat-select>
                                <mat-error  *ngIf="addNotesForm.controls['priority'].touched && addNotesForm.controls['priority'].hasError('required')">
                                    This is required field.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">
                    <div class="input_wrap full-width">
                        
                        <div class="input_sec">
                            <mat-form-field fxFlex="auto" appearance="outline">
                                <mat-label>Notes</mat-label>
                                <textarea matInput
                                          cdkTextareaAutosize
                                          #autosize="cdkTextareaAutosize"
                                          cdkAutosizeMinRows="5"
                                          cdkAutosizeMaxRows="5" 
                                          formControlName="notes"></textarea>
                                          <mat-error  *ngIf="addNotesForm.controls['notes'].touched && addNotesForm.controls['notes'].hasError('required')">
                                            This is required field.
                                        </mat-error>
                            </mat-form-field>
                        </div>
                        
                    </div>
                    
                </div>              
            </div>
        </form>
       
                <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="!data?.isView" style="padding: 20px 0 0 0;">
                    <button mat-button type="button" class="sub-button" *ngIf="!isLoading" (click)="handleNoteSubmit()">{{data?.noteId ? 'Update' : 'Add'}}</button>
                    <img [ngSrc]="assetsUrl + 'loder.gif'" alt="loaderImg" width="150" height="30" class="loadimg" *ngIf="isLoading"/>
                    <button class="can-button" mat-button *ngIf="!isLoading" (click)="close()">Cancel</button>
                </div>
                <div *ngIf="data?.noteId" class="history_Prt">
                    <h2>Note History</h2>
                    <fury-notes-table 
                    [displayedColumns]="displayedColumns"
                    [dataSource]="noteHistories"
                    [userId]="userId"
                    [editorAccess]="false"
                ></fury-notes-table>
                </div>
    </div>
</div>