import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { EventService } from './event.service';
import * as Swal from 'sweetalert2';
import { AppconfigService } from './appconfig.service';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  API_URL: string;
  httpOptions: { headers: HttpHeaders, observe: 'response' };
  TOKEN: string;
  ROLE: string;
  partnerListSub = new BehaviorSubject<any[]>([]);
  partnerList: Observable<any[]>;
  listOfRole: any = [];

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private event: EventService,
    private router: Router,
    private config: AppconfigService,
  ) {
    this.partnerList = this.partnerListSub.asObservable();
    this.API_URL = config.baseUrl;
    this.TOKEN = this.storage.getDataField('token');
    this.ROLE = this.storage.getDataField('role');

    this.router.events.subscribe((res: any) => {
      if (res instanceof NavigationEnd || res instanceof NavigationStart) {
        this.TOKEN = this.storage.getDataField('token');
        this.ROLE = this.storage.getDataField('role');
        if (this.TOKEN) {
          this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.TOKEN
            }),
            observe: 'response'
          };
        }
      }
    });
    this.event.isLogin.subscribe((res: boolean) => {
      if (res) {
        this.TOKEN = this.storage.getDataField('token');
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.TOKEN
          }),
          observe: 'response'
        };
      }
    });
    if (this.TOKEN) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.TOKEN
        }),
        observe: 'response'
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response'
      };
    }
  }



  private formatErrors(error: any) {
    return throwError(error.error);
  }
  private formatErrorsCustom(error: any) {
    return throwError(error);
  }


  get(path: string, params: HttpParams = new HttpParams()) {
    return this.http.get(`${this.API_URL}${path}`, this.httpOptions);
  }

  post(path: string, body: object = {}) {
    return this.http.post(`${this.API_URL}${path}`, body, this.httpOptions).pipe(catchError(this.formatErrors));
  }
  postCustom(path: any, body: any = {}): Observable<any> {
    return this.http.post(`${this.API_URL}${path}`, body, this.httpOptions).pipe(catchError(this.formatErrorsCustom));
  }

  patch(path: string, body: object = {}) {
    return this.http.patch(`${this.API_URL}${path}`, body, this.httpOptions).pipe(catchError(this.formatErrors));
  }

  delete(path: string, body: object = {}) {
    return this.http.delete(`${this.API_URL}${path}`, this.httpOptions).pipe(catchError(this.formatErrors));
  }
  deleteMulti(path: string, body: any) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.TOKEN
      }),
      body
    };
    return this.http.delete(`${this.API_URL}${path}`, options).pipe(catchError(this.formatErrors));
  }

  update(path: string, body: object = {}) {
    return this.http.put(`${this.API_URL}${path}`, body, this.httpOptions).pipe(catchError(this.formatErrors));
  }

  postMultiData(path: string, file: FormData): Observable<any> {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        'Authorization': 'Bearer ' + this.TOKEN
      }),
      observe: 'response' as 'body'
    };
    return this.http.post(`${this.API_URL}${path}`, file, httpOptionsimg).pipe(catchError(this.formatErrors));
  }

  put(path: string, file: FormData) {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        'Authorization': 'Bearer ' + this.TOKEN
      })
    };
    return this.http.put(`${this.API_URL}${path}`, file, httpOptionsimg)
  }

  upload(path: string, body: FormData) {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        'Authorization': 'Bearer ' + this.TOKEN
      })
    };
    return this.http.put(`https://2ec1d0203b18.ngrok.io/admin/${path}`, body, httpOptionsimg).pipe(catchError(this.formatErrors));
  }
  getGEOLocation() {

    const url = 'https://extreme-ip-lookup.com/json/?key=XR3TguYX40zUBte1FhWw';
    return this.http
      .get(url)
      .pipe(
        catchError(this.formatErrors)
      );
  }
  getlocation() {
    this.getGEOLocation().subscribe((location)=>{
      if (location) {
        this.storage.saveIp(location);
      }
    },
    (err)=>{
    this.alert(err.message, 'error');
  })
  }

  getCityState(zipcode: AbstractControl, params: HttpParams = new HttpParams()): Promise<any> {
    if (!zipcode.valid) return Promise.resolve(null);
    zipcode?.setErrors({ loading: true });

    return this.http.get(`${this.API_URL}common/get-location-details-by-zip-code/${zipcode.value}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors)).toPromise().then((res: any) => {
        if (res && res.status != 'ZERO_RESULTS') {
          let zipData = res
          let city = zipData.results[0].address_components.find((item: any) => item.types[0] == "locality" || item.types[0] == "political");
          city = city.long_name;
          let state = zipData.results[0].address_components.find((item: any) => item.types[0] == "administrative_area_level_1");
          state = state.long_name;
          return ({ city, state, zip: zipcode.value })
        } else return null;
      }, () => {
        zipcode?.setErrors({ notValid: null });
        zipcode?.updateValueAndValidity();
        return false
      })
      .then(res => {
        if (res === null) { zipcode?.setErrors({ notValid: true }); }
        else {
          zipcode?.setErrors({ notValid: null });
          zipcode?.updateValueAndValidity();
        };
        return res;
      }, () => { zipcode?.markAsTouched(); zipcode?.setErrors({ notValid: true }); zipcode?.updateValueAndValidity(); return Promise.reject(false); });
  }


  alert(message: string, type: any) {
    return Swal.default.fire({
      title: message,
      icon: type,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
    });
  }


  restrictChar(event: any, max_chars: number) {
    if (event.target.value.length > max_chars) {
      event.target.value = event.target.value.substr(0, max_chars);
    }
  }

  allowAlphabate(event: any): boolean {
    let status = true;
    const regex = new RegExp('^[a-zA-Z ]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      status = false;
    }
    return status;
  }
  restrictSpace(event: any) {
    event.target.value = event.target.value ? event.target.value.trimStart() : '';
    if (event.target.placeholder !== 'Email Address' && event.target.placeholder !== 'Business Email Address') {
      event.target.value = event.target.value.match(/\./g) ? event.target.value.replace(/\./g, '') : event.target.value;
      if (event.target.value.match(/\s/g)) {
        event.target.value = event.target.value.replace(/\s\s+/g, ' ');
        return event.target.value;
      }
    } else {
      if (event.target.value.match(/\s/g)) {
        event.target.value = event.target.value.replace(/\s/g, '');
        return event.target.value;
      }
    }
    return event.target.value;
  }

  imageResize(event: any, imageWidth: number, imageHeight: number) {
    return new Promise((resolve, reject)=>{
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.resizeImage(reader.result, imageWidth, imageHeight).then((compressed: any) => {
          if (compressed) {
            resolve(compressed);
          };
        })
      }
      reader.readAsDataURL(file);
    }
  })
  }

  resizeImage(src: any, newX: any, newY: any) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d')!;
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        resolve(data);
      }
      img.onerror = error => reject(error);
    })
  }

  setRoleID(id: any) {
    if(this.listOfRole.indexOf(id) === -1) this.listOfRole.push(id);
  }
  setRoleIdWithArryForm(data: any){
    this.listOfRole = data;
  }

  removeRoleID(id: any) {
    const index = this.listOfRole.indexOf(id);
    if (index !== -1) {
      this.listOfRole.splice(index, 1);
    }
  }

  getRoleID() {
    return this.listOfRole;
  }

  clearRoleID() {
    this.listOfRole = [];
  }

  allowNumbers(event: any): boolean {
    let status = true;
    const regex = new RegExp('^[0-9]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      status = false;
    }
    return status;
  };
  changeInput(e: any, fromKeyup = false) {
    e.value = e.value ? e.value.trimStart() : '';
    // e.value = /\d/.test(e.value.substr(0, 1)) ? e.value : null;
    if (e.value.match(/\s/g)) {
      e.value = e.value.replace(/\s\s+/g, ' ');
      return e.value;
    }
    return e;
  }

  routeToPage(url){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigateByUrl(url);
  }
}
