import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { checkoutAddress } from '../models/user-data.models';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(
    private storage: StorageService
  ) { }

  private Loading = new BehaviorSubject(true);
  isLoading = this.Loading.asObservable();
  private Login = new BehaviorSubject(this.storage.isAuthenticate());
  isLogin = this.Login.asObservable();
  private Sold = new BehaviorSubject(true);
  leadData = new EventEmitter<any>();
  isHttpRequest = new Subject<boolean>();

  private delete = new BehaviorSubject(false);
  isDeleted = this.delete.asObservable();

  private pageChange = new BehaviorSubject(false);
  isPageChange = this.pageChange.asObservable();

  private navItem = new BehaviorSubject('');
  navItemData = this.navItem.asObservable();

  private loginobjectCreate = new Subject<any>();
  loginobjectCreated = this.loginobjectCreate.asObservable();
  public userData = new BehaviorSubject({});
  public count = new BehaviorSubject(0);
  public UserDeviceType = new BehaviorSubject({});
  public checkoutAddress = new BehaviorSubject<checkoutAddress | any>({});
  private claimStatus$ = new BehaviorSubject([]);
  public getClaimStatus$ = this.claimStatus$.asObservable();

  setLoginEmmit(isLogin: boolean) {
    return this.Login.next(isLogin);
  }
  setLoaderEmmit(isLoading: boolean) {
    return this.Loading.next(isLoading);
  }
  setEventEmit(isSold: boolean) {
    return this.Sold.next(isSold);
  }

  setlogindata(data: any) {
    return this.loginobjectCreate.next(data);
  }

  setdeleteemit(data: boolean) {
    return this.delete.next(data);
  }
  setpageemit(data: boolean) {
    return this.pageChange.next(data);
  }
  setClaimStatus(data: any[]) {
    this.claimStatus$.next(data);
  }
}
