// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  assetsUrl: 'https://cdn.elitehw.com/',
  backend: 'http://localhost:4200', // Put your backend here
  pusher: {
    appId: '1691117',
    key: '8dac0d580bec0972a260',
    secret: '6274f9289569663dc9b5',
    cluster: 'mt1',
  },
};
