export default {
  user: {
    getUser: (userId: string) => `admin/user/get-user/${userId}`,
    getAllClaimUsers: "admin/user/get-all-claim-users",
    getAllPartnerUsers: "admin/user/get-all-partner-users",
    getLeadSources: `admin/user/get-lead-sources`,
    getClaimUserRepLog: (userId: string)=> `admin/claim/file-claim/get-file-claim-histories-by-rep/${userId}`
  },
  customerDashboard: {
    getCustomerDashboardKpi :(userId: string) =>  `admin/consumer/dashboard/get-customer-dashboard-kpi/${userId}`,
    getCustomerAgreements :(userId: string) =>  `admin/consumer/service-agreements/get-agreements/${userId}`,
    getLeads: (userId: string) => `admin/consumer/checkout/get-leads/${userId}`,
    getFeedbacks :(userId: string) =>  `admin/consumer/get-feedbacks/${userId}`,
    updateUser : (userId: string) => `admin/consumer/update-user/${userId}`,
    getUser : (userId: string) => `admin/consumer/get-user/${userId}`,
    getFeedback: (reviewId: string) => `admin/consumer/get-feedback/${reviewId}`,
    savefeedback:(reviewId: string) =>`admin/consumer/save-feedback${reviewId}`,
    getNote: (noteId: string) => `admin/consumer/get-note/${noteId}`,
    saveNote: (noteId: string) => `admin/consumer/save-note${noteId}` ,
    getNotes: (noteId: string) => `admin/consumer/get-notes/${noteId}`,
    getClaims: (userId: string) => `admin/consumer/get-file-claims/${userId}`,
    getBillings: (userId: string) => `admin/consumer/get-billings/${userId}`,
    saveSpecialRequest: (requestId: string) => `admin/consumer/save-special-request${requestId}`,
    getSpecialRequest: (requestId: string) => `admin/consumer/get-special-request/${requestId}`,
    getSpecialRequests: (requestId: string) => `admin/consumer/get-special-requests/${requestId}`,
    getAllState: 'admin/state/get-all-states?page=1&size=9999&searchBy=title&searchType=contains',
    getPropertyType: 'admin/plan/property-type/get-property-types?page=1&size=9999&searchBy=title&searchType=contains',
    getPropertySize : 'admin/plan/property-size/get-property-sizes?page=1&size=9999&searchBy=title&searchType=contains',
    leadGenerate: 'admin/consumer/checkout/save-lead',
    checkout: {
      getCopayFeeSettings: 'admin/settings/copay-fee/get-copay-fee-settings',
      getPlanPrice: (planPropertyTypeId: string, planPropertySizeId: string) => `admin/consumer/checkout/get-plan-prices?planPropertyTypeId=${planPropertyTypeId}&planPropertySizeId=${planPropertySizeId}`,
      getOptionalCoverItems: 'admin/consumer/checkout/get-optional-covered-items',
      getSurChargeByState: (state: string) => `admin/consumer/checkout/get-all-states/${state}`,
      SaveCheckout : 'admin/consumer/checkout/save-order',
      getBankDetail: (routingNumber:string)=> `admin/consumer/checkout/get-bank-detail/${routingNumber}`
    },
    getNoteHistory :(noteId: string) => `admin/consumer/get-note-history/${noteId}`,
    getSpecialRequestHistory: (requestId: string) => `admin/consumer/get-special-request-history/${requestId}`,
    getFeedbackHistory: (reviewId: string) => `admin/consumer/get-feedback-history/${reviewId}`,
    getClaimDetails: (claimId:string) => `admin/claim/file-claim/get-file-claim/${claimId}`,
    getAgreementDetails: (agreementId:string) => `admin/consumer/service-agreements/get-agreement-by-id/${agreementId}`,
    getAgreementViewDetails: (agreementId:string) => `admin/consumer/service-agreements/get-agreement-view-detail/${agreementId}`,
    getAdditionalQuestionTypes: `admin/claim/additional-question/get-additional-question-types`,
    updateFileClaim: (claimId:string, section: string) => `admin/claim/file-claim/update-file-claim/${section}/${claimId}`,
    getAgents: `admin/claim/file-claim-info/get-agents`,
    getFileClaimInfo: (claimId:string,actionTypes:string ) => `admin/claim/file-claim-info/get-file-claim-info/${claimId}/${actionTypes}`,
    updateFileClaimInfo: (claimId:string,actionTypes:string ) => `admin/claim/file-claim-info/update-file-claim-info/${claimId}/${actionTypes}`,
    changeClaimStatus: (claimId: string)=> `admin/claim/file-claim/change-status/${claimId}`,
    getFileClaimLeftPanelDetail: (claimId: string)=>  `admin/claim/file-claim/get-file-claim-left-panel-detail/${claimId}`,
    deleteDiagnosis: (diagnosisId: string)=> `admin/claim/file-claim-info/delete-diagnosis-info/${diagnosisId}`,
    getClaimDouments: (claimId: string) => `admin/claim/file-claim/update-file-claim/get-documets/${claimId}`,
    uploadClaimDocument: (claimId: string) => `admin/claim/file-claim/update-file-claim/upload-documets/${claimId}`,
    updateClaimDocument: (claimId: string, claimDocumentId: string)=> `admin/claim/file-claim/update-file-claim/upload-documets/${claimId}/${claimDocumentId}`,
    deleteClaimDocument: (claimDocumentId: string) => `admin/claim/file-claim/update-file-claim/delete-documets/${claimDocumentId}`,
    getFileClaimDocumentsHistories: (claimId:string) =>  `admin/claim/file-claim/get-file-claim-documents-histories/${claimId}`,
    getClaimQuestionAnswers: 'admin/claim/claim-question/get-claim-questions',
    getClaimViewKpi: (claimId:string) =>  `admin/claim/file-claim/get-claim-view-kpi/${claimId}`,
    getFileClaimLabel: (claimId:string) =>   `admin/claim/file-claim/get-file-claim-label/${claimId}`,
    getAgreementLabel: (agreementId:string) => `admin/consumer/service-agreements/get-agreement-label/${agreementId}`,
    getCustomerDashboardLabel: (userId:string) =>   `admin/consumer/dashboard/get-customer-dashboard-label/${userId}`,
    payment: {
      upcomingPayment: (userId: string)=> `admin/consumer/get-upcoming-payments/${userId}`,
      successfulPayment: (userId: string, paymentType: string)=> `admin/consumer/get-payment-history/${userId}/${paymentType}`,
      getChargeManually: (userId: string) => `admin/consumer/charge-manually/get-charge-manuallies/${userId}`
    },
    getAgreementKpi: (agreementId:string) =>  `admin/consumer/service-agreements/get-agreement-kpi/${agreementId}`,
    getAgreementFinancialStats: (agreementId:string) => `admin/consumer/service-agreements/get-agreement-financial-stats/${agreementId}`,
    createChargeManually: 'admin/consumer/charge-manually/create-charge-manually',
    saveCheckInformation: (agreementId:string) => `admin/consumer/service-agreements/save-check-information/${agreementId}`,
    saveComplaint : `admin/consumer/save-complaint`,
    getAgreementDetailSectionWise: (agreementId: string, actionTypes: string) => `admin/consumer/service-agreements/get-agreement-action/${agreementId}/${actionTypes}`,
    updateAgreement: (agreementId: string, actionType: string)=> `admin/consumer/service-agreements/update-agreement-action/${agreementId}/${actionType}`,
    getAgreementOptionalItems: (agreementId: string)=> `admin/consumer/service-agreements/get-agreement-optional-item-details/${agreementId}`,
    updateAgreementOptionalItems: (agreementId: string)=> `admin/consumer/service-agreements/update-agreement-optional-item-details/${agreementId}`,
    updateClaimReplacement: (claimReplacementId: string) => `admin/claim/file-claim/update-file-claim/claim-replacement/${claimReplacementId}`,
    getAgreementStats : (agreementId: string)=> `admin/consumer/service-agreements/get-agreement-financial-stats/${agreementId}`,
    cancelAgreement: (agreementId: string, actionType: string)=> `admin/consumer/service-agreements/update-agreement-action/${agreementId}/${actionType}`,
    getUserDefaultPayment: (userId: string) => `admin/consumer/get-user-default-payment-details/${userId}`,
    getAgreementCalculation: (agreementId: string)=> `admin/consumer/service-agreements/get-agreement-plan-calculation/${agreementId}`,
    getAgreementIntllmentCalculation: (agreementId: string) => `admin/consumer/service-agreements/get-agreement-installment-calculation/${agreementId}`,
    updateAgreementCoverage:(agreementId: string, saveData : number)=> `admin/consumer/service-agreements/update-agreement-coverage/${agreementId}/${saveData}`,
    getAgreementRefundableAction: (agreementId: string, amount: number) => `admin/consumer/service-agreements/get-agreement-refundable-action/${agreementId}?refundAmount=${amount}`,
    getConsumerDetailByEmail: (emailId: string) => `admin/consumer/service-agreements/get-consumer-details/${emailId}`, 
    transferAgreement: (agreementId: string)=> `admin/consumer/service-agreements/transfer-agreement/${agreementId}`,
    getTechnicians: (companyId:string) => `admin/claim/file-claim-info/get-technicians/${companyId}`,
    renewAgreement: (agreementId: string, saveData: number) => `admin/consumer/service-agreements/renew-agreement/${agreementId}/${saveData}`,
    getRetentionAgreements : (userId: string)=> `admin/consumer/service-agreements/get-retended-agreements/${userId}`,
    assignAgreement: (agreementId: string)=> `admin/consumer/service-agreements/assign-user-to-agreement/${agreementId}`,
    getClaimChangesLog: (claimId: string) => `admin/claim/file-claim/get-file-claim-histories/${claimId}`,
    changeAgreementStatus: (agreementId: string) => `admin/consumer/service-agreements/change-escrow-payment-status/${agreementId}`,
    getTransactionDetail:(agreementId: string) => `admin/consumer/service-agreements/get-transaction-detail/${agreementId}`,
    cancelWithVoidTransaction :(agreementId: string) => `admin/consumer/service-agreements/cancel-with-void-transaction/${agreementId}`,
  },
  blockContacts : {
    blockNumbers: {
      getBlockPhoneNumbers : 'admin/settings/block-contact/get-blocked-phones',
      deleteBlockPhoneNumber : (blockPoneNumberId: string) => `admin/settings/block-contact/delete-blocked-phone/${blockPoneNumberId}`,
      addBlockPhoneNumbers: 'admin/settings/block-contact/save-blocked-phone'
    },
    blockEmails: {
      getBlockEmails : 'admin/settings/block-contact/get-blocked-emails',
      deleteBlockEmail: (blockEmailId: string) => `admin/settings/block-contact/delete-blocked-email/${blockEmailId}`,
      addBlockEmails: 'admin/settings/block-contact/save-blocked-email'
    }
  },
  fileAClaim: {
    getPlanCoverItem:
      'admin/consumer/file-claim/get-covered-items?page=1&size=9999',
    submitFileAclaim: 'admin/consumer/file-claim/submit-claim',
    claimQuestion: (id: string) => `admin/consumer/file-claim/get-covered-item-question/${id}`,
    getAfterAdditionalQuestion: (id: string) =>
      `admin/consumer/file-claim/get-covered-item-after-additional-question/${id}`,
    getBeforeAdditionalQuestion: (id: string) =>
      `admin/consumer/file-claim/get-covered-item-before-additional-question/${id}`,
    getFirstQuestions: (id: string) =>
      `admin/consumer/file-claim/get-covered-item-first-question/${id}`,
    getChildAdditionalQuestionByParents: (id: string) =>
      `admin/consumer/file-claim/get-child-additional-question/${id}`,
    getCoverItemDetails: (id: string) => `admin/consumer/file-claim/get-covered-item/${id}`,
    getServiceAgreementList: (searchBy: string, keyWord: string) => `admin/consumer/file-claim/get-agreements?searchBy=${searchBy}&keywords=${keyWord}`,
    getServiceAgreementDetails: (agreementNumber: string) => `admin/consumer/service-agreements/get-agreement/${agreementNumber}`,
    additionalQuestion: {
      getAdditionalQuestionDetails: (questionId:string)=> `admin/claim/additional-question/get-additional-question/${questionId}`,
      getAllYesNoTypeAdditionalQuestion: 'admin/claim/additional-question/get-additional-questions?page=1&size=9999&type=Yes/No&isNotDependent=1',
      createAdditionalQuestion : 'admin/claim/additional-question/create-additional-question',
      updateAdditionalQuestion: (questionId: string)=> `admin/claim/additional-question/update-additional-question/${questionId}`,
      getAllAdditionalQuestionType: 'admin/claim/additional-question/get-additional-question-types',
    },
    claimStatus: "admin/email/get-all-email-template-action-statuses",
    getClaimKpiData: "admin/claim/file-claim/get-file-claims-kpi",
    getUserSubRole: (claimId: string, userId: string)=> `admin/user/assigned-user-sub-roles?claimId=${claimId}&userId=${userId}`,
    getAssignClaimUser: "admin/user/get-assign-claim-users",
    assignUserToClaim: (claimId: string) => `admin/user/assign-user-to-claim/${claimId}`,
    getClaimAssignmentHistory: (claimId: string) => `admin/user/get-claim-assignment-history/${claimId}`,
    getFileClaimList: "admin/claim/file-claim/get-file-claims",
    getClaimDetails: (claimId: string) => `admin/claim/file-claim/get-file-claim/${claimId}`,
    changeMultipleStatus : 'admin/claim/file-claim/change-multiple-status',
    getSubRolesAndUsers: "admin/user/claim-user-or-sub-roles",
    assignUserToMultipleClaim: "admin/user/assign-user-to-multiple-claim",
    fetchClaimNotes: (claimId: string) => `admin/consumer/get-claim-notes/${claimId}`,
    getAssignedFileClaimUser: (claimId: string) => `admin/claim/file-claim/get-assigned-file-claim-user/${claimId}`,
    assignFileClaimUser: (claimId: string) => `admin/claim/file-claim/assign-file-claim-user/${claimId}`,
    changeServiceStatus: (claimId: string) => `admin/claim/file-claim/change-service-status-claim-info/${claimId}`,
    getAssignTechnician: (claimId: string) => `admin/claim/file-claim/get-assign-technician/${claimId}`,
    changeClaimPriority: (claimId: string) => `admin/claim/file-claim/change-prioritization/${claimId}`,
    getAllFileClaimHistory: "admin/claim/file-claim/get-all-file-claim-histories",
    getPurchaseClaims: "admin/claim/purchase-claim/get-purchase-claims",
    getPurchaseClaimItems: (claimId: string) => `admin/claim/purchase-claim/get-purchase-claim-items/${claimId}`,
    createPurchase: "admin/claim/purchase-claim/create-purchase",
    updatePurchase: (purchaseId: string) => `admin/claim/purchase-claim/update-purchase/${purchaseId}`,
    getPayoutClaims: "admin/claim/payout-claim/get-payout-claims",
    createPayout: "admin/claim/payout-claim/create-payout",
    updatePayout: (claimPayoutItemId: string) => `admin/claim/payout-claim/update-payout/${claimPayoutItemId}`,
    getPayouts: (claimId: string) => `admin/claim/payout-claim/get-all-payouts/${claimId}`
  },
  checkoutSettings: {
    getCheckoutSettings: 'admin/checkout-setting/get-checkout-setting',
    postCheckoutettings: 'admin/checkout-setting/save-checkout-setting'
  },
  leadPartner:{
    getLeadpartnerDashboard: 'admin/lead-partner/lead-partner-dashboard',
    getParticularLeadPartnerDashboard: (leadPartnerId:string)=> `admin/lead-partner/get-lead-partner/${leadPartnerId}`,
    getLeadPartnerList: 'admin/lead-partner/get-all-lead-partners',
    deleteLeadPartner: (id:string) => `admin/lead-partner/delete-lead-partner/${id}`,
    changeLeadPartnerStatus: (leadPartnerId: string) => `admin/lead-partner/change-status/${leadPartnerId}`
  },
  globalIpAddress: {
    saveGlobalIpAddress: 'admin/settings/global-access-ip/save-global-access-ip-settings',
    getGlobalIpAddress: 'admin/settings/global-access-ip/get-global-access-ip-settings'
  },
  planManagement: {
    planPrice: {
      clonePlanPrice: 'admin/plan/plan-price/clone-plan-price'
    }
  },
  reputation: {
    statisticsReview : {
      statisticsReview: 'admin/review/get-review-statistics',
    },
    feedback:{
      feedbackStatus: (reviewId: string) => `admin/review/feedback/change-status/${reviewId}`
    }
  },
  settings: {
    pageContent: {
      getAllPageContent: 'admin/settings/content-mananagement-system/get-all-contents',
      createPageContent: 'admin/settings/content-mananagement-system/create-content',
      updatePageContent: (contentManagementId: string) => `admin/settings/content-mananagement-system/update-content/${contentManagementId}`,
      getPageContentDetails: (contentManagementId: string)=> `admin/settings/content-mananagement-system/get-content/${contentManagementId}`,
      deletePageContent: (contentManagementId: string)=> `admin/settings/content-mananagement-system/delete-content/${contentManagementId}`
    },
    faq : {
      getAllFaqs: 'admin/settings/faq/get-all-faqs',
      getFaq:  (faqId) => `admin/settings/faq/get-faq/${faqId}`,
      getFaqCategories: 'admin/settings/faq/get-faq-categories',
      getFaqSubCategories: 'admin/settings/faq/get-faq-sub-categories',
      createFaq: 'admin/settings/faq/create-faq',
      updateFaq : (faqId) => `admin/settings/faq/update-faq/${faqId}`,
      deleteFaq: (faqId) => `admin/settings/faq/delete-faq/${faqId}`,
    },
    globalEmailTemplate : {
      getEmailTemplates : 'admin/email/get-email-templates',
      getAllEmailTemplateAction:  'admin/email/get-all-email-template-action',
      getEmailTemplate:(emailTemplateId)=> `admin/email/get-email-template/${emailTemplateId}`,
      createEmailTemplate : 'admin/email/create-email-template',
      updateEmailTemplate:(emailTemplateId: string)=> `admin/email/update-email-template/${emailTemplateId}`,
      deleteEmailTemplate: (emailTemplateId: string) => `admin/email/delete-email-template/${emailTemplateId}`

    },
    statusManagement: {
      getAllStatus: "admin/settings/claim-status/get-all-status",
      getStatusDetails: (statusId: string)=> `admin/settings/claim-status/get-claim-status/${statusId}`,
      createStatus: "admin/settings/claim-status/create-claim-status",
      updateStatus: (statusId: string) => `admin/settings/claim-status/update-claim-status/${statusId}`,
      deleteStatus: (statusId: string) => `admin/settings/claim-status/delete-claim-status/${statusId}`,
      assignClaimStatus: (claimStatusTypeId: string) => `admin/settings/claim-status/assign-claim-status/${claimStatusTypeId}`,
      getAllStatusType: "admin/settings/claim-status/get-all-status-type",
      getAllStatusByType: (claimStatusTypeId: string) => `admin/settings/claim-status/get-all-status-by-type/${claimStatusTypeId}`
    },
    menuManagement: {
      getAllMenuList: "admin/admin-menu/get-admin-menus",
      getMenuDetails: (menuId: string) => `admin/admin-menu/get-admin-menu/${menuId}`,
      createMenu: "admin/admin-menu/create-admin-menu",
      updateMenu: (menuId: string) => `admin/admin-menu/update-admin-menu/${menuId}`,
      getParentMenuDetails: (menuId: string) => `admin/admin-menu/get-all-admin-menus/${menuId}`,
      deleteMenu: (id: string) => `admin/admin-menu/delete-admin-menu/${id}`,
      reArrangeMenu: "admin/admin-menu/rearrange-admin-menus",
    },
    service: {
      getAllServices: "admin/service/get-all-services",
      getServiceDetail: (serviceId: string) => `admin/service/get-service/${serviceId}`,
      createService: "admin/service/create-service",
      updateService: (serviceId: string) => `admin/service/update-service/${serviceId}`,
      getCoverItems: "admin/plan/covered-item/get-covered-items",
      getServicesByClaimId: (claimId: string) => `admin/company/get-services-by-claim/${claimId}`,
    },
    globalLeadPartnerPopup: {
      saveLeadPartnerPopup: "admin/settings/lead-partner-global-pop-up/save-lead-partner-global-popup-settings",
      getLeadPartnerPopup: "admin/settings/lead-partner-global-pop-up/get-lead-partner-global-pop-up-settings"
    },
    loaderContent: {
      getLoaderContent: "admin/settings/page-content/get-loader-content-settings",
      saveLoaderContent: "admin/settings/page-content/save-loader-content-settings"
    }
  },
  state: {
    getAllState: "admin/state/get-all-states"
  },
  partners: {
    serviceProvider: {
      getServiceProviderPartnerCoverageArea: (id: string)=> `admin/partner/get-service-provider-partner-covearge-area/${id}`,
      postServiceProviderPartnerCoverageArea : (id: string) => `admin/partner/save-service-provider-partner-covearge-area/${id}`,
      getServices: `admin/company/get-services`,
      getAllCompanies:`admin/company/get-all-companies`,
      createCompany: `admin/company/create-company`,
      getCompany : (companyId: string)=> `admin/company/get-company/${companyId}`,
      updateCompany: (companyId: string)=>  `admin/company/update-company/${companyId}`,
      getAllTechnician: `admin/technician/get-all-technician`,
      getTechnician: (technicianId: string)=> `admin/technician/get-technician/${technicianId}`,
      updateTechnician: (technicianId: string)=> `admin/technician/update-technician/${technicianId}`,
      deleteTechnician : (technicianId: string)=>`admin/technician/delete-technician/${technicianId}`,

    }
  },
  agentDashboard: {
    activitStatus: (userId: string)=> `admin/agent/change-activity-status/${userId}`,
    assignNotes: (agentId: string)=> `admin/agent/note/get-notes/${agentId}`,
    assignSpecialRequest: (agentId: string)=> `admin/agent/special-request/get-special-requests/${agentId}`,
    getAgentLogHours: (userId: string) => `admin/agent/get-today-log/${userId}`,
    getConceirgeClaims: (agentId: string) => `admin/agent/get-assigned-conceirge-task/${agentId}`,
    getCoordinatorClaims: (agentId: string) => `admin/agent/get-assigned-coordinator-task/${agentId}`,
    getClaimSupervisor: (agentId: string) => `admin/agent/get-assigned-supervisor-task/${agentId}`,
    getdashboardInfo: (agentId: string) => `admin/agent/get-agent-dashboard-details/${agentId}`,
    getAccountExecutiveClaim: (agentId: string) => `admin/agent/get-assigned-account-executive-claims/${agentId}`,
    getAssignedUsersClaims: (agentId: string) => `admin/agent/get-assigned-users-claims/${agentId}`,
    getPurchaserClaims: (agentId: string) => `admin/agent/get-assigned-purchaser-task/${agentId}`,
  },
  auth: {
    getMeData:"auth/me",
  },
  customerServiceAgreement: {
    gettAllServiceAgreementList : "admin/consumer/service-agreements/get-agreements",
    getAgreementDetails : (agreementNumber: string)=> `admin/consumer/service-agreements/get-agreement/${agreementNumber}`,
    getAgreementPdf: (agreementId: string, isPdf: number) => `admin/consumer/service-agreements/get-agreement-by-id/${agreementId}/${isPdf}`,
    getAgreementKpiData : "admin/service-agreement/get-agreements-kpi"
  },
  consumer: {
    getCustomerKpis : "admin/consumer/get-consumers-kpi",
  },
  chargeCopay:{
    getChargeCopayList: "admin/claim/charge-copay/get-all-charge-copays",
    chargeCopay: "admin/claim/charge-copay/payment"
  },
  contact: {
    getContacts: "admin/contact/get-contacts",
    changeContactStatus: (contactId: string) => `admin/contact/update-contact-status/${contactId}`
  },
  leadManagement: {
    getCallRailLeadList: "admin/call-rail-lead/get-call-rail-leads",
    getCallRailLead:(callRailLeadId: string) =>  `admin/call-rail-lead/get-call-rail-lead/${callRailLeadId}`,
    getIncomingLeads:(partnerId:string)=> `admin/partner-dashboard/get-incoming-leads/${partnerId}`,
    getPartnerUserKpiData: (partnerId: string, parentTabName: string, leadSource: string) => `admin/partner-dashboard/get-dashboard-data/${partnerId}/${parentTabName}${leadSource !== "All" ? `/${leadSource}` : ""}`,
    getAllConsumer: "admin/partner/get-consumers",
    changeConsumer: "admin/lead/merge-accounts",
    getPartnerLeadSource: (partnerId: string) => `admin/partner-dashboard/get-partner-lead-sources/${partnerId}`,
    getPartnerCallRailLeads : (partnerId: string, leadSource: string) => `admin/partner-dashboard/get-call-rail-leads/${partnerId}${leadSource !== "All" ? `/${leadSource}` : ""}`,
    getCallRailKpiData: "admin/call-rail-lead/get-call-rail-leads-kpi",
    getHomeSecurityKpiData: "admin/home-security-lead/get-home-security-leads-kpi",
    getHomeSecurityLeads: "admin/home-security-lead/get-home-security-leads"
  },
  affiliateUser: {
    getAffiliateUserData : (mailToken: string) => `auth/get-affiliate-user/${mailToken}`,
    createPassword: (mailToken: string) => `auth/reset-password/${mailToken}`
  },
  userManagement:{
    resetPartnerPassword: (userId: string) => `admin/user/reset-partner-password/${userId}`,
  }
};
