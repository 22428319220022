import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class SearchUserService {

  constructor(private apiService : ApiService, private commonService: CommonService) { }


  displayResult(value) {
    return value?.firstName ? `${this.commonService.getFullName(value?.firstName, value?.lastName)} ${value?.role?.title ? '- ' + value?.role?.title :''}` : ''
  }


  getAutoCompleteData(formControl: AbstractControl) {
   return formControl.valueChanges
    .pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this.filterSeachResults(val || '')
      })       
    );
  }

  private filterSeachResults(val: string){
    return this.getUserList(val)
     .pipe(
       map((res: any) => {
        return res?.body?.users?.data;
      
       }))
   }  



   private getUserList(keywords: string) {
    return  this.apiService
      .get(
        `admin/user/get-all-users?page=1&size=10&keywords=${keywords}&searchBy=fullName&searchType=contains`
      )
  }
}
