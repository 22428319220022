import { Component, Inject, OnInit } from '@angular/core';
import { SidenavService } from 'src/app/layout/sidenav/sidenav.service';
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { UserData } from 'src/app/models/user-data.models';
import { EventService } from 'src/app/services/event.service';
import { ApiService } from 'src/app/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorType } from 'src/app/enums/error-type.enum';
import endpoints from 'src/app/helpers/endpoints';
import { fadeInRightAnimation } from 'src/@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from 'src/@fury/animations/fade-in-up.animation';
import { scaleInAnimation } from 'src/@fury/animations/scale-in.animation';
import { SearchUserService } from "../../services/search-user.service";
import { CommonService } from 'src/app/services/common.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'fury-special-request',
  templateUrl: './special-request.component.html',
  styleUrls: ['./special-request.component.scss'],
  animations: [fadeInUpAnimation, fadeInRightAnimation, scaleInAnimation],
})
export class SpecialRequestComponent implements OnInit {
  userId = "";
  requestId = "";
  createdBy = "";
  agreementNumber =  null;
  fileClaimId =  null;
  specialRequestForm: FormGroup;
  ngUnsubscribe = new Subject<void>();
  filteredOptions: Observable<any>;
  isView = false;
  specialRequestHistories = [];
  displayedColumns: string[] = [
    "status",
    "assigned",
    "agreementNumber",
    'updatedOn',
    'updatedBy',
    'type',
    'priority',
    'request',
  ];
  assetsUrl = environment.assetsUrl + 'images/';
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<SpecialRequestComponent>,
    private sidenavService: SidenavService, private router: Router, private activateRoute: ActivatedRoute,
    private eventService: EventService,
    public searchUserService: SearchUserService,
    private apiService: ApiService,
    public commonService: CommonService,
    public location: Location,
    @Inject(MAT_DIALOG_DATA) public data: {
      fileClaimId: null;
      userId: null;
      agreementNumber: null;
      requestId: string;
      isView: boolean; agreementId: string, assignedUser: any 
},) { }

  ngOnInit(): void {
  
    this.isView = this.data.isView;
    this.requestId = this.data.requestId;
    this.agreementNumber = this.data.agreementNumber ||  null;
    this.fileClaimId = this.data.fileClaimId ||  null;
    this.userId = this.data.userId ||  null;
    this.eventService.userData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: UserData) => {
      if (res.userId) {
        this.createdBy = res.userId;
        this.setupSpecialRequestForm();
        if (this.isView) this.specialRequestForm.disable();
      }
    });

    setTimeout(() => {
      this.sidenavService.close();
    }, 0);

    this.filteredOptions = this.searchUserService.getAutoCompleteData(this.specialRequestForm.get('assigned'));
    if (!this.requestId) return;
    this.getSpecialRequest();
    this.getSpecialRequestHistory()
  }

  getSpecialRequestHistory() {
    if(!this.requestId) return;
    this.apiService
      .get(endpoints.customerDashboard.getSpecialRequestHistory(this.requestId))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.specialRequestHistories = res?.body?.specialRequestHistories;
      });
  }
  getSpecialRequest() {
    this.apiService
      .get(endpoints.customerDashboard.getSpecialRequest(this.requestId))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        const specialRequest = res?.body?.specialRequest;
        this.specialRequestForm.setValue({
          assigned: specialRequest?.assigned,
          createdBy: specialRequest?.createdBy?.userId,
          agreementNumber: specialRequest?.agreementNumber,
          request: specialRequest?.request,
          status: specialRequest?.status,
          type: specialRequest?.type,
          priority: specialRequest?.priority,
          postedBy: this.createdBy,

        });
      });
  }


  setupSpecialRequestForm() {
    const agreementNumber = this.agreementNumber || null;
    this.specialRequestForm = new FormGroup({
      assigned: new FormControl(null),
      createdBy: new FormControl(this.createdBy),
      agreementNumber: new FormControl(agreementNumber),
      request: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      priority: new FormControl(null, [Validators.required]),
      postedBy: new FormControl(this.createdBy),
    });
  }

  handleSpecialRequestSubmit() {
    if (this.specialRequestForm.invalid) {
      this.specialRequestForm.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const payload = this.createPayload();
    payload.assigned = this.specialRequestForm?.get('assigned')?.value?.userId || null
    const params = this.requestId ? `/${this.requestId}` : ''
    this.apiService
      .post(
        endpoints.customerDashboard.saveSpecialRequest(params),
        payload
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.isLoading = false;
          this.apiService.alert(res.body.message, ErrorType.Success);
          this.dialogRef.close(res.body);
        },
        (err) => {
          this.apiService.alert(err.message, ErrorType.Error);
          this.isLoading = false;
        }
      );
  }

  createPayload() {
    let payload = { ...this.specialRequestForm.value, userId: this.userId };
    if (!!this.fileClaimId) {
      payload = { ...payload, claimId: this.fileClaimId }
    }
    return payload
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
