import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { UserData } from '../models/user-data.models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  TEMP = {
    KEY: '_eliteT',
    PASSWORD: '90590348534YYIU!@00'
  };
  USER = {
    KEY: 'eliteuSer',
    PASSWORD: '!##0895*()?:}95047834&&tes'
  };
  PAGE = {
    KEY: '_elitpage',
    PASSWORD: '!##0895*()?:}95047834&&tes'
  };
  SESSION_ID = {
    KEY: '_session_address@unique',
    PASSWORD: '69587442@87!@00879%unique'
  };
  IP = {
    KEY: '_FORdevise',
    PASSWORD: '!##0895*()?:}95047834&&tes'
  };
  constructor(
    private cookie: CookieService
  ) { }


  private encription(data: any, secret: string) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secret);
  }
  private decription(data: any, secret: string) {
    const bytes = CryptoJS.AES.decrypt(data.toString(), secret);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  setTempData(ID: string) {
    return this.cookie.set(this.TEMP.KEY, this.encription(ID, this.TEMP.PASSWORD).toString(), null, '/');
  }
  getTempData() {
    const DATA = this.cookie.get(this.TEMP.KEY) !== null ? this.cookie.get(this.TEMP.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.TEMP.PASSWORD);
    } else {
      return undefined;
    }
  }
  clearTempData() {
    return this.cookie.delete(this.TEMP.KEY, '/');
  }

  setUser(data: UserData) {
    return this.cookie.set(this.USER.KEY, this.encription(data, this.USER.PASSWORD).toString(), null,'/');
  }
  getUser() {
    if (this.cookie.get(this.USER.KEY)) {
      return this.decription(this.cookie.get(this.USER.KEY), this.USER.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearUser() {
     this.cookie.delete(this.USER.KEY, '/');
  }
  // TOKEN
  getDataField(type: string) {
    const userData = this.getUser();
    if(userData){
      return userData[type];
    } else {
      return undefined;
    }
  }


  setPage(ID: string | number, KEY: string) {
    return this.cookie.set(KEY, this.encription(ID, this.PAGE.PASSWORD).toString()), null,'/';
  }
  getPage(KEY: string) {
    const DATA = this.cookie.get(KEY) !== null ? this.cookie.get(KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.PAGE.PASSWORD);
    } else {
      return undefined;
    }
  }
  clearPage(KEY: string) {
    return this.cookie.delete(KEY, '/');
  }

  isAuthenticate() {
        if (this.getDataField('token') !== undefined) {
      return true;
    } else {
      return false;
    }
  }
  setSessionId(sessionID: any) {
    return this.cookie.set(this.SESSION_ID.KEY, this.encription(sessionID, this.SESSION_ID.PASSWORD).toString());
  }
  getSessionId() {
    const DATA = this.cookie.get(this.SESSION_ID.KEY) !== null ? this.cookie.get(this.SESSION_ID.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.SESSION_ID.PASSWORD);
    } else {
      return undefined;
    }
  }
  clearSessionId() {
    return this.cookie.delete(this.SESSION_ID.KEY);
  }
  saveIp(data: any) {
    return this.cookie.set(this.IP.KEY,
      this.encription(data, this.IP.PASSWORD).toString(), 30000);
  }
  getIp() {
    const DATA = this.cookie.get(this.IP.KEY) !== null ? this.cookie.get(this.IP.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.IP.PASSWORD);
    } else {
      return undefined;
    }
  }

}
