import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SidenavService } from 'src/app/layout/sidenav/sidenav.service';
import { ActivatedRoute, Router } from "@angular/router";
import { UserData } from 'src/app/models/user-data.models';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import endpoints from 'src/app/helpers/endpoints';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ErrorType } from 'src/app/enums/error-type.enum';
import { SearchUserService } from '../../../services/search-user.service';
import { CommonService } from 'src/app/services/common.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
@Component({
  selector: 'fury-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss']
})
export class NoteModalComponent implements OnInit, OnDestroy {
  createdBy = "";
  addNotesForm: FormGroup;
  ngUnsubscribe = new Subject<void>();
  filteredOptions: Observable<any>;
  noteHistories = [];
  displayedColumns: string[] = [
    "status",
    "assigned",
    "agreementNumber",
    'updatedOn',
    'updatedBy',
    'priority',
    'notes',
  ];
  assetsUrl = environment.assetsUrl + 'images/';
  isLoading = false;
  constructor(
    private apiService: ApiService,
    private eventService: EventService,
    public searchUserService: SearchUserService,
    public commonService: CommonService,
    private location: Location,
    public dialogRef: MatDialogRef<NoteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {userId: string, noteId: any, isView: boolean, agreementNo: any, ClaimId: any},
  ) { }

  ngOnInit(): void {
    this.eventService.userData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: UserData) => {
      if (res.userId) {
        this.createdBy = res.userId;
        this.setupAddNotesForm();
        if (this.data?.isView) this.addNotesForm.disable();
      }
    });

    this.filteredOptions = this.searchUserService.getAutoCompleteData(this.addNotesForm.get('assigned'));

    if(this.data.noteId){
      this.getNote();
      this.getNoteHistory();
    }
  }

  setupAddNotesForm() {
    const agreementNumber = this.data?.agreementNo || null;
    this.addNotesForm = new FormGroup({
      assigned: new FormControl(null),
      createdBy: new FormControl(this.createdBy),
      agreementNumber: new FormControl(agreementNumber),
      notes: new FormControl(null, [Validators.required]),
      priority: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      postedBy: new FormControl(this.createdBy),
    });
   
  }

  getNote() {
    this.apiService
      .get(endpoints.customerDashboard.getNote(this.data.noteId))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        const note = res?.body?.note;
        this.addNotesForm.setValue({
          assigned: note?.assigned,
          createdBy: note?.createdBy?.userId,
          agreementNumber: note?.agreementNumber,
          notes: note?.notes,
          priority: note?.priority,
          status: note?.status,
          postedBy: this.createdBy
        });
      });
  }

  getNoteHistory() {
    if(!this.data.noteId) return;
    this.apiService
      .get(endpoints.customerDashboard.getNoteHistory(this.data.noteId))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.noteHistories = res?.body?.noteHistories;
      });
  }

  handleNoteSubmit() {
    if (this.addNotesForm.invalid) {
      this.addNotesForm.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const payload = this.createPayload();
    payload.assigned = this.addNotesForm?.get('assigned')?.value?.userId || null
    const params = this.data.noteId ? `/${this.data.noteId}` : ''
    this.apiService
      .post(
        endpoints.customerDashboard.saveNote(params),
        payload
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: HttpResponse<any>) => {
          this.apiService.alert(res.body.message, ErrorType.Success);
          this.isLoading = false;
          // this.getNoteHistory();
         this.dialogRef.close(res.body);
        },
        (err) => {
          this.apiService.alert(err.message, ErrorType.Error);
          this.isLoading = false;
        }
      );
  }

  createPayload() {
    let payload = { ...this.addNotesForm.value, userId: this.data.userId };
    if (this.data.ClaimId) {
      payload = { ...payload, claimId: this.data.ClaimId }
    }
    return payload;
  }

  redirectToDashboard() {
    this.location.back();
  }
  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
