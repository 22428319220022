import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { ThemeService } from '../../../@fury/services/theme.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { EventService } from 'src/app/services/event.service';
import { default as screenfull } from 'screenfull';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  isFullscreen = false;

  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;

  items$: Observable<SidenavItem[]>;
  userData!:any;
  constructor(
    private router: Router,
    private sidenavService: SidenavService,
    private themeService: ThemeService,
    private storageService: StorageService,
    private apiService: ApiService,
    private commonService: CommonService,
    private event : EventService,
    private cd: ChangeDetectorRef
    ) {
      this.userData = this.storageService.getUser();
      this.event.userData.next(this.storageService.getUser());
  }

  ngOnInit() {
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );
    screenfull.on('change', () => this.setFullscreen(screenfull.isFullscreen));
  }

  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }

  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    this.sidenavService.setExpanded(true);
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  ngOnDestroy() {
  }
  logOut() {
    this.apiService.delete('auth/logout').subscribe((res: any) => {
      if(res.body) {
        this.commonService.clearAfterLogout(res.body.message, 'success');
        this.storageService.clearUser();
      }
    },
    err => {
      this.commonService.clearAfterLogout('Somthing went wrong', 'error');
    });    
  }


  toggleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
      this.setFullscreen(screenfull.isFullscreen);
    }
  }

  setFullscreen(isFullscreen: boolean) {
    this.isFullscreen = isFullscreen;
    this.cd.markForCheck();
  }
}
